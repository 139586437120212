// Stylesheets
import "./theme.scss";

// ACE Library JS
import "@accor/ace-ui-core";

// COMMON JS Components
import "./components/common/quick-access/v2/js/quick-access-v2.js";
import "./components/common/callouts/v1/callout-container/js/callout-container.js";
import "./components/common/heading-hero/v1/js/heading-hero.js";
import "./components/common/social-media/v1/js/social-media.js";
import "./components/common/heading/v1/js/heading.ts";
import "./components/common/newsletter/v1/js/newsletter.ts";
// CONTENT JS Components
import "./components/content/highlight/v1/js/highlight.ts";
// Structure JS Components
import "./components/structure/base-page-template/v1/js/base-page-template.js";

document.dispatchEvent(
  new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT),
);
